import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { CardActions, FocusContainer, FontIcon, SelectField } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import Footer from '../../SharedComponents/Footer';
import Header from '../../SharedComponents/Header';
import Loader from '../../SharedComponents/Loader';
import Notification from '../../SharedComponents/Notification';
import Tooltip from '../../SharedComponents/Tooltip';
import { storeItem } from '../../store/item/actions';
import { addItem } from '../../store/items/actions';

const QUALITIES = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

class AnalysisForm extends Component {
    state = {
        showLoader: false,
        qualityError: false,
        quality: ''
    };

    componentDidMount() {
        const {
            user,
            history
        } = this.props;

        if (isEmpty(user)) {
            history.push('/login');
        }

        ReactGA.pageview('/analysis');
    }

    notifySuccess = message => toast.success(message);
    notifyError = message => toast.error(message);

    validate = () => {
        this.setState({
            qualityError: !this.state.quality
        });

        return (
            !this.state.quality
        );
    };

    updateItem = (evt, save) => {
        evt.preventDefault();
        const { history, match: { params: { cutId } } } = this.props;

        if (!save) {
            history.push(`/details/${cutId}`);
        } else {
            const formError = this.validate();

            if (!formError) {
                const { item } = this.props;

                if (save) {
                    last(item).analysis = {
                        quality: parseInt(this.state.quality, 10)
                    };
                    history.push(`/details/${cutId}/Analysis`);
                } else {
                    history.push(`/details/${cutId}`);
                }
            } else {
                this.notifyError('Error with some of the input fields');
            }
        }
    };

    render() {
        const {
            showLoader,
            loaderHint,
            qualityError
        } = this.state;

        return (
            <div className="form-page">
                <Header ctaEnabled>
                    <Col md={3} xl={4} className="heading hidden-md-down">
                        <span className="heading-text">
                            Order Materials
            </span>
                    </Col>
                </Header>
                <div className="content-stretch form-wrapper">
                    <FocusContainer
                        containFocus
                        component="form"
                        className="md-grid"
                        onSubmit={(evt) => this.updateItem(evt, true)}
                        aria-labelledby="update-item"
                    >
                        <p>You should make sure to upload the analysis document before completing this form.</p>
                        <SelectField
                            id="quality"
                            required
                            label="Quality"
                            className="md-cell"
                            menuItems={QUALITIES}
                            position={SelectField.Positions.BELOW}
                            error={qualityError}
                            errorText="This field is required."
                            dropdownIcon={<FontIcon>expand_more</FontIcon>}
                            value={this.state.quality}
                            onChange={(v) => this.setState({ quality: v })}
                        />
                    </FocusContainer>
                    <Notification />
                    <div>
                        <Loader showLoader={showLoader} text={loaderHint} />
                        <CardActions className="md-cell md-cell--12">
                            <button className={`button secondary ${showLoader ? 'hidden' : ''}`} onClick={(evt) => this.updateItem(evt, false)}>
                                Cancel
              </button>
                            <button className={`button submit-analaysis-cta ${showLoader ? 'hidden' : ''}`} onClick={(evt) => this.updateItem(evt, true)}>
                                Submit Analysis
              </button>
                        </CardActions>
                    </div>
                </div>
                <Tooltip />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    item: state.item,
    project: state.project,
});

const mapDispatchToProps = dispatch => ({
    addItem: cutId => dispatch(addItem(cutId)),
    storeItem: item => dispatch(storeItem(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withCookies(AnalysisForm)));
