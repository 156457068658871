export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const RESET = 'RESET';
export const RESET_ITEM = 'RESET_ITEM';
export const STORE_ITEM = 'STORE_ITEM';
export const STORE_ITEMS = 'STORE_ITEMS';
export const ADD_ITEM = 'ADD_ITEM';
export const LOAD_EVENTS = 'LOAD_EVENTS';
export const LOAD_PROJECT_SETTINGS = 'LOAD_PROJECT_SETTINGS';
export const LOAD_EVENT_MAPPINGS = 'LOAD_EVENT_MAPPINGS';
