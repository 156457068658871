import * as moment from 'moment';
import React from 'react';

const Details = ({ item, fields }) => {
    const updated = timestamp => moment.duration(Date.now() - timestamp).humanize();

    return (
        <div className="detail-section-wrapper">
            {fields.body.map((field, index) => (
                <div className="details-section" key={fields.headers[index]}>
                    <span className="label">{fields.headers[index]}</span>
                    <span className="value">
                        {item[field] === null ? 'N/A' :
                            field === 'timestamp' ? updated(item[field]) :
                                field === 'materials' ?
                                    <React.Fragment>
                                        <span className="caption">Fertilizer</span><span className="value2">{item[field].fertilizer}</span>
                                        <br />
                                        <span className="caption">Silage Additives</span><span className="value2">{item[field].silageAdditives}</span>
                                        <br />
                                        <span className="caption">Bale Film</span><span className="value2">{item[field].roundBaleFilm}</span>
                                    </React.Fragment> :
                                    field === 'analysis' ? item[field].quality :
                                        field === 'bales' ?
                                            <React.Fragment>
                                                <span className="caption">Harvested</span><span className="value2">{item[field].length}</span>
                                                <br />
                                                <span className="caption">Sold</span><span className="value2">{item[field].filter(b => b.purchaser).length}</span>
                                                <br />
                                                <span className="caption">Consumed</span><span className="value2">{item[field].filter(b => b.consumed).length}</span>
                                            </React.Fragment>
                                            : item[field]
                        }
                    </span>
                </div>
            ))}
        </div>
    );
};

export default Details;
