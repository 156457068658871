/* eslint-disable react/jsx-key */
import React, { PureComponent } from 'react';
import { withCookies } from 'react-cookie';
import { Tab, Tabs, TabsContainer } from 'react-md';
import sizeMe from 'react-sizeme';
import updateStep from '../../utils/cookie';
import Documents from '../Documents';
import Explorer from '../Explorer';
import Loader from '../../SharedComponents/Loader';
import StatusList from '../Status';

class ItemTabs extends PureComponent {
    state = {
        activeTabIndex: this.props.activeTabIndex,
    };

    static defaultProps = {
        activeTabIndex: 0,
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ activeTabIndex: nextProps.activeTabIndex });
    }

    onTabChange = newActiveTabIndex => {
        this.setState({ activeTabIndex: newActiveTabIndex });
        this.props.onTabChange(newActiveTabIndex);
    };

    render() {
        const {
            cookies,
            item,
            statuses,
            size,
            documentStorage,
            onUploadComplete,
            fileUploadEnabled,
            showLoader,
            loaderHint
        } = this.props;
        const { activeTabIndex } = this.state;

        const components = [
            <StatusList statuses={statuses} />,
            <Explorer />,
            <Documents item={item} onUploadComplete={onUploadComplete} fileUploadEnabled={fileUploadEnabled} />,
        ];

        return (
            <div className="tabs-wrapper">
                <TabsContainer
                    activeTabIndex={activeTabIndex}
                    onTabChange={this.onTabChange}
                >
                    <Tabs tabId="item-details" mobile={size.width <= 768}>
                        <Tab label="Status" className="status-tab" onClick={() => updateStep(cookies, 7)} />
                        <Tab label="Tangle" className="tangle-tab" onClick={() => {
                            updateStep(cookies, 5);
                            updateStep(cookies, 31);
                        }} />
                        {documentStorage ? (
                            <Tab label="Documents" className="documents-tab" onClick={() => updateStep(cookies, 19)} />
                        ) : null}
                    </Tabs>
                </TabsContainer>
                {(activeTabIndex === 0 || activeTabIndex === 1) && (
                    <div className={`loader-wrapper ${showLoader ? '' : 'hidden'}`}>
                        <Loader showLoader={showLoader} text={loaderHint} />
                    </div>
                )}
                {components[activeTabIndex]}
            </div>
        );
    }
}

export default sizeMe({ monitorHeight: false })(withCookies(ItemTabs));
