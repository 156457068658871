import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { CardActions, FocusContainer, FontIcon, SelectField, TextField } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import Footer from '../SharedComponents/Footer';
import Header from '../SharedComponents/Header';
import Loader from '../SharedComponents/Loader';
import Notification from '../SharedComponents/Notification';
import Tooltip from '../SharedComponents/Tooltip';
import { storeItem } from '../store/item/actions';
import { addItem } from '../store/items/actions';
import updateStep from '../utils/cookie';
import { getItem } from '../utils/firebase';
import { createItemChannel } from '../utils/mam';

const FIELDS = ['Field 1', 'Field 2', 'Field 3', 'Field 4', 'Field 5'];

class CreateItemPage extends Component {
    state = {
        showLoader: false,
        idError: false,
        fieldError: false,
        id: '',
        field: '',
        hideTour: false
    };

    componentDidMount() {
        const { user, history } = this.props;
        if (isEmpty(user)) {
            history.push('/login');
        } else {
            ReactGA.pageview('/new');
            const now = new Date();
            const year = now.getFullYear();
            const month = `${now.getMonth() + 1}`.padStart(2, '0');
            const day = `${now.getDate()}`.padStart(2, '0');
            const hours = `${now.getHours()}`.padStart(2, '0');
            const minutes = `${now.getMinutes()}`.padStart(2, '0');
            const seconds = `${now.getSeconds()}`.padStart(2, '0');
            this.setState({ id: `${year}-${month}-${day}-${hours}-${minutes}-${seconds}` });
        }

        updateStep(this.props.cookies, 2);
    }

    notifySuccess = message => toast.success(message);
    notifyError = message => toast.error(message);

    validate = () => {
        this.setState({
            idError: !this.state.id,
            fieldError: !this.state.field
        });

        return (
            !this.state.id ||
            !this.state.field
        );
    };

    prepareItem = async event => {
        event.preventDefault();
        const formError = this.validate();
        const { cookies, history, storeItem, addItem, user, project } = this.props;

        if (!formError) {
            const { id, name, previousEvent } = user;
            const request = {
                field: this.state.field,
                farmer: name,
                fieldDetails: {
                    soilType: 'Clay',
                    location: {
                        lat: 52.52956250000001,
                        lng: 13.413046874999981
                    },
                },
                status: previousEvent[0],
                materials: null,
                cultivation: null,
                analysis: null,
                bales: null
            };

            // Format the item ID to remove dashes and parens
            const cutId = this.state.id.replace(/[^0-9a-zA-Z_-]/g, '');

            const firebaseSnapshot = await getItem(cutId);
            if (!firebaseSnapshot) {
                updateStep(cookies, 4);
                cookies.set('cutId', cutId, { path: '/' });

                this.setState({ showLoader: true });
                const eventBody = await createItemChannel(project, cutId, request, id);

                await addItem(cutId);
                await storeItem([eventBody]);

                ReactGA.event({
                    category: 'Create cut',
                    action: 'Create cut',
                    label: `Cut ID ${cutId}`
                });

                history.push(`/details/${cutId}`);
            } else {
                this.notifyError(`${upperFirst(project.trackingUnit)} exists`);
            }
        } else {
            this.notifyError('Error with some of the input fields');
        }
    };

    render() {
        const {
            showLoader,
            idError,
            fieldError
        } = this.state;
        const { project: { trackingUnit } } = this.props;
        const unit = upperFirst(trackingUnit);

        return (
            <div className="form-page">
                <Header ctaEnabled>
                    <Col md={3} xl={4} className="heading hidden-md-down">
                        <span className="heading-text">
                            Prepare {trackingUnit}
                        </span>
                    </Col>
                </Header>
                <div className="content-stretch form-wrapper">
                    <FocusContainer
                        // focusOnMount
                        containFocus
                        component="form"
                        className="md-grid"
                        onSubmit={this.prepareItem}
                        aria-labelledby="create-item"
                    >
                        <TextField
                            value={this.state.id}
                            readOnly={true}
                            id="cutId"
                            className="input-cutId"
                            label={`${unit} ID`}
                            type="text"
                            error={idError}
                            errorText="This field is required."
                        />
                        <SelectField
                            id="field"
                            required
                            label="Field"
                            className="md-cell field"
                            menuItems={FIELDS}
                            position={SelectField.Positions.BELOW}
                            error={fieldError}
                            errorText="This field is required."
                            dropdownIcon={<FontIcon>expand_more</FontIcon>}
                            value={this.state.field}
                            onClick={() => this.setState({ hideTour: true }) }
                            onChange={(v) => { 
                                updateStep(this.props.cookies, 3);
                                this.setState({ field: v, hideTour: false }); 
                            }}
                        />
                    </FocusContainer>
                    <Notification />
                    <div>
                        <Loader showLoader={showLoader} />
                        <CardActions className="md-cell md-cell--12">
                            <Link to="/list" className={`button secondary ${showLoader ? 'hidden' : ''}`}>
                                Cancel
                            </Link>
                            <button className={`button prepare-cta ${showLoader ? 'hidden' : ''}`} onClick={this.prepareItem}>
                                Prepare
                            </button>
                        </CardActions>
                    </div>
                </div>
                {!this.state.hideTour && (
                    <Tooltip />
                )}
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    project: state.project,
});

const mapDispatchToProps = dispatch => ({
    addItem: cutId => dispatch(addItem(cutId)),
    storeItem: item => dispatch(storeItem(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withCookies(CreateItemPage)));
