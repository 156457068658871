import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import upperFirst from 'lodash/upperFirst';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { Button } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import Footer from '../SharedComponents/Footer';
import Header from '../SharedComponents/Header';
import Loader from '../SharedComponents/Loader';
import Notification from '../SharedComponents/Notification';
import Tooltip from '../SharedComponents/Tooltip';
import { resetStoredItem, storeItem } from '../store/item/actions';
import { storeItems } from '../store/items/actions';
import updateStep from '../utils/cookie';
import { appendItemChannel, fetchItem } from '../utils/mam';
import Details from './Details';
import Tabs from './Tabs';

const StatusButtons = ({ statuses, onClick, showLoader }) => {
    const arrStatuses = typeof statuses === 'string' ? [statuses] : statuses;

    return (
        <div className="details-page-button-container">
            {arrStatuses.map(status => (
                <Button
                    key={status}
                    className={`details-page-button ${status.replace(/\s+/g, '-').toLowerCase()} ${showLoader ? 'hidden' : ''}`}
                    raised
                    onClick={() => onClick(status)}>
                    {status}
                </Button>
            ))}
        </div>
    );
};

class DetailsPage extends Component {
    state = {
        showLoader: false,
        loaderHint: null,
        fetchComplete: false,
        fileUploadEnabled: true,
        statusUpdated: false,
        statuses: [],
        item: null,
        activeTabIndex: 0,
    };

    async componentDidMount() {
        const {
            user,
            item,
            items,
            history,
            match: {
                params: { cutId, newStatus },
            },
        } = this.props;
        if (isEmpty(user)) {
            history.push('/login');
        }
        if (!cutId || isEmpty(items)) {
            history.push('/list');
        } else if (isEmpty(item) || item[0].cutId !== cutId) {
            this.retrieveItem(cutId);
        } else {
            this.setState({
                showLoader: false,
                fetchComplete: true,
                item: last(item),
                statuses: this.getUniqueStatuses(item),
            }, () => {
                if (newStatus) {
                    this.appendToItem(newStatus, true);
                }
            });

        }
        ReactGA.pageview('/details');
    }

    notifySuccess = message => toast.success(message);
    notifyWarning = message => toast.warn(message);
    notifyError = message => toast.error(message);

    getUniqueStatuses = itemEvents =>
        uniqBy(itemEvents.map(event => pick(event, ['status', 'timestamp'])), 'status');

    appendToItem = async (status, hasData) => {
        const { cookies, history, match: { params: { cutId } } } = this.props;

        if (status === 'Order Materials' && !hasData) {
            updateStep(cookies, 8);
            history.push(`/materials/${cutId}/0`);
        } else if (status === 'Existing Materials' && !hasData) {
            history.push(`/materials/${cutId}/1`);
        } else if (status === 'Cultivate Crop' && !hasData) {
            updateStep(cookies, 11);
            history.push(`/cultivate/${cutId}`);
        } else if (status === 'Create Bale Batch' && !hasData) {
            updateStep(cookies, 13);
            history.push(`/balebatchcreate/${cutId}`);
        } else if (status === 'Analysis' && !hasData) {
            updateStep(cookies, 21);
            history.push(`/analysis/${cutId}`);
        } else if (status === 'Consume Bales' && !hasData) {
            history.push(`/balesconsume/${cutId}`);
        } else if (status === 'Purchase Bales' && !hasData) {
            updateStep(cookies, 29);
            history.push(`/balespurchase/${cutId}`);
        } else {
            const { project } = this.props;

            if (status === 'Order Materials') {
                updateStep(cookies, 9);
            } else if (status === 'Materials Received') {
                updateStep(cookies, 10);
            } else if (status === 'Cultivate Crop') {
                updateStep(cookies, 12);
            } else if (status === 'Create Bale Batch') {
                updateStep(cookies, 14);
            } else if (status === 'Send for Analysis') {
                updateStep(cookies, 15);
            } else if (status === 'Analysis') {
                updateStep(cookies, 22);
            } else if (status === 'Purchase Bales') {
                updateStep(cookies, 30);
            }

            if (status) {
                ReactGA.event({
                    category: 'Status update',
                    action: `Updated status to "${status}"`,
                    label: `Cut ID ${cutId}`
                });
            }

            this.setState({ showLoader: true, fetchComplete: false, loaderHint: 'Updating Tangle' });
            const response = await appendItemChannel(this.props, status);
            if (response) {
                this.notifySuccess(`${upperFirst(project.trackingUnit)} status updated`);
                this.setState({
                    showLoader: false,
                    fileUploadEnabled: true,
                    loaderHint: null
                });
                this.retrieveItem(response);
            } else {
                this.setState({ showLoader: false, loaderHint: null });
                this.notifyError('Something went wrong');
            }
        }
    };

    storeItemCallback = item => {
        this.props.storeItem(item);
    };

    setStateCalback = (item, statuses) => {
        this.setState({ item, statuses });
    };

    retrieveItem = cutId => {
        const { items, user, project: { trackingUnit } } = this.props;
        const item = items[cutId];
        this.setState({ showLoader: true, loaderHint: 'Fetching data' });
        this.props.resetStoredItem();
        this.props.storeItems(user, cutId);
        const promise = new Promise(async (resolve, reject) => {
            try {
                await fetchItem(
                    item.mam.root,
                    item.mam.sideKey,
                    this.storeItemCallback,
                    this.setStateCalback
                );

                this.setState({ showLoader: false, fetchComplete: true, loaderHint: null });
                return resolve();
            } catch (error) {
                this.setState({ showLoader: false, loaderHint: null });
                return reject(this.notifyError(`Error loading ${trackingUnit} data`));
            }
        });

        return promise;
    };

    onTabChange = newActiveTabIndex => {
        const { match: { params: { cutId } } } = this.props;
        this.setState({ activeTabIndex: newActiveTabIndex });

        const tabs = ['Status', 'Tangle', 'Documents'];

        ReactGA.event({
            category: 'Tab change',
            action: `Selected tab "${tabs[newActiveTabIndex]}"`,
            label: `Cut ID ${cutId}`
        });
    };

    onUploadComplete = metadata => {
        const { cookies, item, match: { params: { cutId } } } = this.props;

        const lastItem = last(item);

        for (let i = 0; i < metadata.length; i++) {
            lastItem.documents.push(metadata[i]);
        }

        this.setState({ fileUploadEnabled: true, activeTabIndex: 2 }, () => {
            this.notifySuccess('Upload complete, the document meta data will be added to the tangle next time you update the status');

            updateStep(cookies, 20);

            ReactGA.event({
                category: 'Document upload',
                action: 'Uploaded document(s)',
                label: `Cut ${cutId}`
            });
        });
    };

    render() {
        const {
            fileUploadEnabled,
            showLoader,
            loaderHint,
            statusUpdated,
            statuses,
            item,
            fetchComplete,
            activeTabIndex,
        } = this.state;
        const {
            cookies,
            user,
            match: { params: { cutId } },
            project: { documentStorage, detailsPage },
        } = this.props;

        if (!item) return <Loader showLoader={showLoader} />;

        const nextEvents = user.nextEvents[item.status.toLowerCase().replace(/[- ]/g, '')];

        const containerHeading = (
            <React.Fragment>
                {
                    typeof detailsPage.title === 'string'
                        ? item[detailsPage.title]
                        : detailsPage.title.map(field => item[field]).join(' → ')
                }
            </React.Fragment>
        );

        return (
            <div className="details-page">
                <Header ctaEnabled>
                    <Col md={3} xl={4} className="heading hidden-md-down">
                        <span className="heading-text">
                            #{cutId},&nbsp;{containerHeading}
                        </span>
                    </Col>
                </Header>
                <div className="content-stretch details-wrapper">
                    <div className="md-block-centered">
                        <div className="route-cta-wrapper">
                            <Link
                                to="/list"
                                className="button secondary back-cta"
                                onClick={() => updateStep(cookies, 10)}
                            >
                                <span>&lt;</span><span>Back</span>
                            </Link>
                            <h3 className="ca-title">
                                #{cutId}
                                <br />
                                {containerHeading}
                            </h3>
                            {user.canAppendToStream && !statusUpdated && nextEvents ? (
                                <StatusButtons statuses={nextEvents} onClick={this.appendToItem} showLoader={showLoader} />
                            ) : null}
                        </div>
                        <Tabs
                            activeTabIndex={activeTabIndex}
                            item={item}
                            statuses={statuses}
                            documentStorage={documentStorage}
                            fileUploadEnabled={fileUploadEnabled}
                            onTabChange={this.onTabChange}
                            onUploadComplete={this.onUploadComplete}
                            showLoader={showLoader}
                            loaderHint={loaderHint}
                        />
                        {activeTabIndex === 0 && (
                            <Details item={item} fields={detailsPage} />
                        )}
                    </div>
                </div>
                <Notification />
                <Tooltip fetchComplete={fetchComplete} activeTabIndex={activeTabIndex} />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    item: state.item,
    items: state.items,
    project: state.project,
});

const mapDispatchToProps = dispatch => ({
    storeItem: item => dispatch(storeItem(item)),
    storeItems: (user, cutId) => dispatch(storeItems(user, cutId)),
    resetStoredItem: () => dispatch(resetStoredItem()),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withCookies(DetailsPage)));
