/* eslint-disable quotes */
import React from 'react';

export default [
    {
        // Step 0 - login page
        title: "Let's begin",
        content: (
            <div className="tooltip-content">
                It is soon time to harvest one of your fields and to prepare collaboration and supplier input for this cut.
                <br /><br />
                <span className="tooltip-action">Log in as &quot;Farmer 1&quot; to prepare the cut of the field</span>
            </div>
        ),
        target: '.farmer-1-cta',
        placement: 'right',
        isRounded: true
    },
    {
        // Step 1 - list page
        content: (
            <div className="tooltip-content">
                As you can see there is already a list of earlier cuts. Prepare cut to start collecting data.
                <br /><br />
                <span className="tooltip-action">Click on &quot;Prepare cut&quot;</span>
            </div>
        ),
        target: '.create-new-cta',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 2 - create new page
        content: (
            <div className="tooltip-content">
                An ID for your cut has been generated based on date and time.<br /><br />
                <span className="tooltip-action">Select the field for the cut</span>
            </div>
        ),
        target: '.field',
        placement: 'right',
        isRounded: false
    },
    {
        // Step 3 - create new page
        content: (
            <div className="tooltip-content">
                &nbsp;<br /><br />
                <span className="tooltip-action">Create a digital twin to represent this new cut and store data on the Tangle</span>
            </div>
        ),
        target: '.prepare-cta',
        placement: 'right',
        isRounded: true
    },
    {
        // Step 4 - details page
        content: (
            <div className="tooltip-content">
                A digital twin of the cut is created on the Tangle and a MAM channel is opened, for recording the future events and data input to this cut. This allows digital tracking including adding analysis and information sharing -  written and accessible by approved parties. There is no need for exchange of papers and no risk of falsified information.<br /><br />
                <span className="tooltip-action">Check Tangle to explore the data that has been uploaded to the IOTA Tangle</span>
            </div>
        ),
        target: '.tangle-tab',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 5 - details page, tangle tab
        content: (
            <div className="tooltip-content">
                &quot;Expand all&quot; to see the data currently uploaded to Tangle and affiliated to the digital twin of this cut.<br /><br />
                A unique MAM data stream has been generated to represent the cut. All future events and data will be stored in this MAM channel - making the Tangle the trusted transaction layer between actors.<br /><br />
                <span className="tooltip-action">Click on &quot;Expand all&quot;</span>
            </div>
        ),
        target: '.md-switch-container',
        placement: 'right',
        isRounded: false
    },
    {
        // Step 6 - details page
        content: (
            <div className="tooltip-content">
                &nbsp;<br /><br />
                <span className="tooltip-action">Click on &quot;Status&quot; to go back</span>
            </div>
        ),
        target: '.status-tab',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 7 - request material
        content: (
            <div className="tooltip-content">
                Request needed material for fertilizing input, round bale film and other supplier material to cultivate the field and prepare the harvest and baling.<br /><br />
                <span className="tooltip-action">Click on &quot;Order materials&quot; </span>
            </div>
        ),
        target: '.order-materials',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 8 - Provide information about material requested as per data points
        content: (
            <div className="tooltip-content">
                Fill in request form for all suppliers to automate the delivery of needed material.<br /><br />
                <span className="tooltip-action">Complete the form for all material input and click on &quot;Place Order&quot;</span>
            </div>
        ),
        target: '.place-order-cta',
        placement: 'right',
        isRounded: true
    },
    {
        // Step 9 - Acknowledge receipt of materials
        content: (
            <div className="tooltip-content">
                The materials have been received from the suppliers. <br /><br />
                <span className="tooltip-action">Click on &quot;Materials received&quot; to acknowledge</span>
            </div>
        ),
        target: '.materials-received',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 10 - Cultivate the "cut" and prepare for harvest and baling
        content: (
            <div className="tooltip-content">
                Supplier material data have been added to the genesis of this cut. Next step is to cultivate the cut.<br /><br />
                <span className="tooltip-action">Click on &quot;Cultivate crop&quot; to provide detailed input about the cultivation process</span>
            </div>
        ),
        target: '.cultivate-crop',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 11 - Cultivate (Fertilize, sow, harvest, bale)
        content: (
            <div className="tooltip-content">
                Step through the cultivation process. Once complete we can create a bale batch and then send samples to the laboratory for analysis.<br /><br />
                <span className="tooltip-action">Click through the steps and then click &quot;Finish&quot;</span>
            </div>
        ),
        target: '.cta-cultivate',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 12 - create bale batch
        content: (
            <div className="tooltip-content">
                Your cut has been cultivated, you can now create a batch of bales from it<br /><br />
                <span className="tooltip-action">Click on &quot;Create Bale Batch&quot;</span>
            </div>
        ),
        target: '.create-bale-batch',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 13 - bale batch details
        content: (
            <div className="tooltip-content">
                Enter the number of bales created in this batch<br /><br />
                <span className="tooltip-action">Click on &quot;Create Batch&quot;</span>
            </div>
        ),
        target: '.create-batch-cta',
        placement: 'right',
        isRounded: true
    },
    {
        // Step 14 - Send feed for analysis
        content: (
            <div className="tooltip-content">
                Samples have been taken from the bale batch and are ready to be sent for analysis.<br /><br />
                <span className="tooltip-action">Click on &quot;Send for Analysis&quot;</span>
            </div>
        ),
        target: '.send-for-analysis',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 15 - logout
        content: (
            <div className="tooltip-content">
                You have requested the laboratory to analyse the fodder samples. Change role to &quot;Laboratory&quot; to complete the analysis.<br /><br />
                <span className="tooltip-action">Click on &quot;Log out&quot;</span>
            </div>
        ),
        target: '.logout-cta',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 16 - Login as Laboratory
        content: (
            <div className="tooltip-content">
                Change to Laboratory role to share the results of the analysis.<br /><br />
                <span className="tooltip-action">Log in as &quot;Laboratory&quot;</span>
            </div>
        ),
        target: '.laboratory-cta',
        placement: 'right',
        isRounded: true
    },
    {
        // Step 17 - list page
        content: (
            <div className="tooltip-content">
                Choose a cut to perform analysis<br /><br />
                <span className="tooltip-action">Select the cut to proceed</span>
            </div>
        ),
        target: '.users-container',
        placement: 'top',
        isRounded: false
    },
    {
        // Step 18 - upload a results document
        content: (
            <div className="tooltip-content">
                You have analyzed the fodder and have created a report document, upload the document.<br /><br />
                <span className="tooltip-action">Go to the &quot;Documents&quot; tab</span>
            </div>
        ),
        target: '.documents-tab',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 19 - details page, documents tab
        content: (
            <div className="tooltip-content">
                Start by adding any document format in pdf, jpg, or word. You will see your file securely and privately stored, associated with the digital twin for the cut.<br /><br />
                <span className="tooltip-action">Drag & Drop your files into this area or browse them to append new document to this cut</span>
            </div>
        ),
        target: '.filepond--wrapper',
        placement: 'top',
        isRounded: false
    },
    {
        // Step 20 - analysis
        content: (
            <div className="tooltip-content">
                Your documents have been uploaded and verified (checkmark symbol shown). You can now continue to complete the rest of the analysis.<br /><br />
                <span className="tooltip-action">Click on &quot;Analysis&quot;</span>
            </div>
        ),
        target: '.analysis',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 21 - fill in results from analysis
        content: (
            <div className="tooltip-content">
                Fill in the results of the analysis and submit them.<br /><br />
                <span className="tooltip-action">Click on &quot;Submit Analysis&quot;</span>
            </div>
        ),
        target: '.submit-analaysis-cta',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 22 - logout
        content: (
            <div className="tooltip-content">
                To verify that Farmer 1 also can see the results of the analysis login as Farmer 1.<br /><br />
                <span className="tooltip-action">Click on &quot;Log out&quot;</span>
            </div>
        ),
        target: '.logout-cta',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 23 - login as farmer1
        content: (
            <div className="tooltip-content">
                Now that the analysis is complete login as Farmer 1 to see the details.<br /><br />
                <span className="tooltip-action">Log in as &quot;Farmer 1&quot;</span>
            </div>
        ),
        target: '.farmer-1-cta',
        placement: 'right',
        isRounded: true
    },
    {
        // Step 24 - list page
        content: (
            <div className="tooltip-content">
                &nbsp;<br /><br />
                <span className="tooltip-action">Select the cut to proceed</span>
            </div>
        ),
        target: '.users-container',
        placement: 'top',
        isRounded: false
    },
    {
        // Step 25 - logout
        content: (
            <div className="tooltip-content">
                Congratulations - Farmer 1 has now completed the cut, ensured full transparency on the result of the cut and issued surplus of round bales for sale for other farmers to search. Login as Farmer 2 to purchase any round bales<br /><br />
                <span className="tooltip-action">Click on &quot;Log out&quot;</span>
            </div>
        ),
        target: '.logout-cta',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 26 - Login as Farmer 2
        content: (
            <div className="tooltip-content">
                Change to Farmer 2 role to search for Round Bales for sale<br /><br />
                <span className="tooltip-action">Log in as &quot;Farmer 2&quot;</span>
            </div>
        ),
        target: '.farmer-2-cta',
        placement: 'right',
        isRounded: true
    },
    {
        // Step 27 - list page
        content: (
            <div className="tooltip-content">
                You are able to view the full genesis of any Round Bales for sale<br /><br />
                <span className="tooltip-action">Click on relevant cut</span>
            </div>
        ),
        target: '.users-container',
        placement: 'top',
        isRounded: false
    },
    {
        // Step 28 - purchase bales
        content: (
            <div className="tooltip-content">
                You have selected a cut with available bales, you can now purchase them<br /><br />
                <span className="tooltip-action">Click on &quot;Purchase Bales&quot;</span>
            </div>
        ),
        target: '.purchase-bales',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 29 - purchase bale details
        content: (
            <div className="tooltip-content">
                Fill in the number of desired bales to purchase<br /><br />
                <span className="tooltip-action">Click on &quot;Purchase&quot;</span>
            </div>
        ),
        target: '.purchase-cta',
        placement: 'right',
        isRounded: true
    },
    {
        // Step 30 - tangle data
        content: (
            <div className="tooltip-content">
                You can now view the complete genesis of the digital twin of the cut on the Tangle, including purchases<br /><br />
                <span className="tooltip-action">Check Tangle to explore the data that has been uploaded to the IOTA Tangle</span>
            </div>
        ),
        target: '.tangle-tab',
        placement: 'bottom',
        isRounded: true
    },
    {
        // Step 31 - details page, tangle tab
        content: (
            <div className="tooltip-content">
                &quot;Expand all&quot; to see all the data uploaded to the Tangle and affiliated to the digital twin of this cut.<br /><br />
                <span className="tooltip-action">Click on &quot;Expand all&quot;</span>
            </div>
        ),
        target: '.md-switch-container',
        placement: 'right',
        isRounded: false

    },
    {
        // Step 32 - done
        content: (
            <div className="tooltip-content">
                This completes the demo as it showcases how all parties can contribute to ensure shared visibility of the full supply chain and how a digital twin of round bales can be issued to enable farmers to manage their stock and place them on a national marketplace<br /><br />
                <span className="tooltip-action">Click on the &quot;Log out&quot; to complete the tour</span>
            </div>
        ),
        target: '.logout-cta',
        placement: 'bottom',
        isRounded: true
    }
];
