/* eslint-disable no-console */
import { getEventMappings, getProjectSettings } from '../../../utils/firebase';
import { LOAD_EVENT_MAPPINGS, LOAD_PROJECT_SETTINGS, RESET } from '../../actionTypes';

export const storeProjectSettings = () => {
    const promise = getProjectSettings();
    return {
        type: LOAD_PROJECT_SETTINGS,
        promise,
    };
};

export const storeEventMappings = () => {
    const promise = getEventMappings();
    return {
        type: LOAD_EVENT_MAPPINGS,
        promise,
    };
};

export const reset = () => ({
    type: RESET,
});
