import React, { Component } from 'react';
import { CookiesProvider } from 'react-cookie';
import { connect } from 'react-redux';
import { HashRouter, Route } from 'react-router-dom';
import CreateNewPage from './CreateNewPage';
import DetailsPage from './DetailsPage';
import AnalysisForm from './Forms/AnalysisForm';
import BaleBatchCreateForm from './Forms/BaleBatchCreateForm';
import BalesConsumeForm from './Forms/BalesConsumeForm';
import BalesPurchaseForm from './Forms/BalesPurchaseForm';
import CultivateForm from './Forms/CultivateForm';
import MaterialForm from './Forms/MaterialForm';
import IntroPage from './IntroPage';
import ListPage from './ListPage';
import LoginPage from './LoginPage';
import { storeProjectSettings } from './store/project/actions';
import TourCompletePage from './TourCompletePage';
import { initializeMamState } from './utils/mam';

class Router extends Component {
    componentDidMount() {
        this.props.storeProjectSettings();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.provider) {
            initializeMamState(nextProps.project.provider, nextProps.project.depth, nextProps.project.mwm);
        }
    }

    render() {
        return (
            <CookiesProvider>
                <HashRouter>
                    <div>
                        <Route path="/" component={IntroPage} exact />
                        <Route path="/tour" component={TourCompletePage} />
                        <Route path="/list" component={ListPage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/new" component={CreateNewPage} />
                        <Route path="/details/:cutId/:newStatus?" component={DetailsPage} />
                        <Route path="/materials/:cutId/:useExisting" component={MaterialForm} />
                        <Route path="/cultivate/:cutId" component={CultivateForm} />
                        <Route path="/analysis/:cutId" component={AnalysisForm} />
                        <Route path="/balebatchcreate/:cutId" component={BaleBatchCreateForm} />
                        <Route path="/balespurchase/:cutId" component={BalesPurchaseForm} />
                        <Route path="/balesconsume/:cutId" component={BalesConsumeForm} />
                    </div>
                </HashRouter>
            </CookiesProvider>
        );
    }
}

const mapStateToProps = state => ({ project: state.project });

const mapDispatchToProps = dispatch => ({
    storeProjectSettings: () => dispatch(storeProjectSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
