/* eslint-disable no-console */
import { createChannel, createMessage, mamAttach, mamFetchAll, TrytesHelper } from '@iota/mam.js';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import memoize from 'lodash/memoize';
import pick from 'lodash/pick';
import { createItem, updateItem } from './firebase';

let node;

export const initializeMamState = memoize((provider) => {
    node = provider;
});

const createNewChannel = async (payload) => {
    try {
        const sideKey = generateHash(81);
        let channelState = createChannel(generateHash(81), 2, 'restricted', sideKey);
        const mamMessage = createMessage(channelState, TrytesHelper.fromAscii(JSON.stringify(payload)));
        channelState.root = mamMessage.root;
        await mamAttach(node, mamMessage, 'FUTUREFARM');
        return channelState;
    } catch (error) {
        console.error('Channel create error', error);
        return null;
    }
};

const appendToChannel = async (payload, savedChannelState) => {
    try {
        let channelState = savedChannelState;
        const mamMessage = createMessage(channelState, TrytesHelper.fromAscii(JSON.stringify(payload)));
        await mamAttach(node, mamMessage, 'FUTUREFARM');
        return channelState;
    } catch (error) {
        console.error('Channel append error', error);
        return null;
    }
};

export const fetchItem = async (root, sideKey, storeItemCallback, setStateCalback) => {
    try {
        const itemEvents = [];
        const convertData = data => {
            const itemEvent = JSON.parse(TrytesHelper.toAscii(data));
            storeItemCallback(itemEvent);
            itemEvents.push(itemEvent);
            setStateCalback(itemEvent, getAllStatuses(itemEvents));
        };

        const fetched = await mamFetchAll(node, root, 'restricted', sideKey, 20);
        if (fetched && fetched.length > 0) {
            for (let i = 0; i < fetched.length; i++) {
                convertData(fetched[i].message);
            }
        }
        return itemEvents[itemEvents.length - 1];
    } catch (e) {
        console.error('fetchItem:', '\n', e);
        return e;
    }
};

const getAllStatuses = itemEvents =>
    itemEvents.map(event => pick(event, ['status', 'timestamp']));

export const createItemChannel = (project, cutId, request) => {
    const promise = new Promise(async (resolve, reject) => {
        try {
            const eventBody = {};
            project.firebaseFields.forEach(field => (eventBody[field] = request[field]));
            eventBody.cutId = cutId;
            eventBody.timestamp = Date.now();

            const messageBody = {
                ...request,
                ...eventBody,
                documents: []
            };

            const channel = await createNewChannel(messageBody);

            if (channel && !isEmpty(channel)) {
                // Create a new item entry using that item ID
                await createItem(eventBody, channel);
            }

            return resolve(eventBody);
        } catch (error) {
            console.log('createItemChannel error', error);
            return reject();
        }
    });

    return promise;
};

export const appendItemChannel = async (props, status) => {
    const {
        project,
        item,
        items,
        match: {
            params: { cutId },
        },
    } = props;
    const { mam } = items[cutId];
    const { documents } = last(item);

    const promise = new Promise(async (resolve, reject) => {
        try {
            if (item) {
                const timestamp = Date.now();
                const newStatus = status;

                const payload = {};
                project.fields.forEach(field => (payload[field] = last(item)[field]));
                const newPayload = {
                    ...payload,
                    timestamp,
                    status: newStatus,
                    documents: documents
                };

                const newItemData = await appendToChannel(newPayload, mam);

                if (newItemData && !isEmpty(newItemData)) {
                    const eventBody = {};
                    project.firebaseFields.forEach(field => (eventBody[field] = last(item)[field]));
                    eventBody.status = newStatus;
                    eventBody.timestamp = timestamp;

                    await updateItem(eventBody, newItemData);

                    return resolve(cutId);
                }
            }
            return reject();
        } catch (error) {
            return reject();
        }
    });

    return promise;
};

export const generateHash = length => {
    if (window.crypto && window.crypto.getRandomValues) {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ9';
        let result = '';
        let values = new Uint32Array(length);
        window.crypto.getRandomValues(values);
        values.forEach(value => (result += charset[value % charset.length]));
        return result;
    } else throw new Error('Your browser is outdated and can\'t generate secure random numbers');
};