import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/storage';
import config from '../config.json';

export const initializeFirebaseApp = () => firebase.initializeApp(config);

export const getFileStorageReference = (pathTofile, fileName) =>
    firebase.storage().ref(`${pathTofile}/${fileName}`);

export const getProjectSettings = () => {
    return axios.get(`${config.rootURL}/settingsGet`).then(r => r.data);
};

export const getEvents = (role) => {
    return axios.get(`${config.rootURL}/eventGet?role=${role}`).then(r => r.data);
};

export const getEventMappings = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.rootURL}/eventsGet`).then(r => resolve(r.data));
    });
};

export const getItem = (cutId) => {
    return axios.get(`${config.rootURL}/cutGet?cutId=${cutId}`).then(r => r.data);
};

export const getItems = (status) => {
    return axios.get(`${config.rootURL}/cutsGet?${status ? `status=${status}` : ''}`).then(r => r.data);
};

export const createItem = (eventBody, mam) => {
    const item = {
        ...eventBody,
        mam
    };

    return axios.post(`${config.rootURL}/cutCreate?cutId=${eventBody.cutId}`, item);
};

export const updateItem = (eventBody, mam) => {
    const item = {
        ...eventBody,
        mam
    };

    return axios.post(`${config.rootURL}/cutUpdate?cutId=${eventBody.cutId}`, item);
};
