import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { DataTable, TableBody, TableColumn, TableHeader, TableRow } from 'react-md';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import Footer from '../SharedComponents/Footer';
import Header from '../SharedComponents/Header';
import Loader from '../SharedComponents/Loader';
import Notification from '../SharedComponents/Notification';
import Tooltip from '../SharedComponents/Tooltip';
import { storeItems } from '../store/items/actions';
import updateStep from '../utils/cookie';
import Autosuggest from './Autosuggest';

class ListPage extends Component {
    state = {
        showLoader: false,
    };

    componentDidMount() {
        const { project, user, history, items } = this.props;
        if (isEmpty(user) || isEmpty(project)) {
            history.push('/login');
        } else {
            ReactGA.pageview('/list');
            if (isEmpty(items) && user.previousEvent) {
                this.setState({ showLoader: true });
                this.props.storeItems(user);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { items } = nextProps;
        if (items.error) {
            this.notifyError(items.error);
        }
        if (
            isEmpty(this.props.items) &&
            nextProps.user.previousEvent &&
            !this.props.user.previousEvent
        ) {
            this.setState({ showLoader: true });
            this.props.storeItems(nextProps.user);
        }
        if (!isEmpty(this.props.items) || this.props.user.previousEvent) {
            this.setState({ showLoader: false });
        }
    }

    createNewItem = () => {
        const { cookies, history } = this.props;
        updateStep(cookies, 2);
        history.push('/new');
    }

    notifyError = message => toast.error(message);

    selectItem = cutId => {
        const { cookies, history, user: { role } } = this.props;

        if (role === 'laboratory') {
            updateStep(cookies, 18);
        }
        if (role === 'farmer-1') {
            updateStep(cookies, 25);
        }
        if (role === 'farmer-2') {
            updateStep(cookies, 28);
        }

        history.push(`/details/${cutId}`);
    }

    render() {
        const { cookies, project, user, history, items } = this.props;
        const { showLoader } = this.state;

        if (isEmpty(project) || !project.listPage) return <div />;

        return (
            <div className="list-page">
                <Header ctaEnabled>
                    <Col md={3} xl={4} className="heading hidden-md-down">
                        <span className="heading-text">
                            Cuts list
                        </span>
                    </Col>
                </Header>
                {user.canCreateStream ? (
                    <div className="cta-wrapper">
                        <button className="button create-new-cta" onClick={this.createNewItem}>
                            Prepare {project.trackingUnit}
                        </button>
                    </div>
                ) : null}
                <div className="content-stretch">
                    <Loader showLoader={showLoader} />
                    <div className={`md-block-centered ${showLoader ? 'hidden' : ''}`}>
                        <Autosuggest
                            items={Object.values(items)}
                            project={project}
                            onSelect={item => history.push(`/details/${item.cutId}`)}
                            trackingUnit={project.trackingUnit}
                        />
                        <DataTable plain className="list-all">
                            <TableHeader>
                                <TableRow>
                                    {project.listPage.headers.map((header, index) => (
                                        <TableColumn
                                            key={header}
                                            className={index === 1 ? 'md-text-center' : index === 2 ? 'md-text-right' : ''}
                                        >
                                            {header}
                                        </TableColumn>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {Object.values(items).map(item => (
                                    <TableRow
                                        key={item.cutId || item.itemId}
                                        onClick={() => this.selectItem(item.cutId)}
                                        className={item.cutId === cookies.get('cutId') ? 'users-container' : ''}
                                    >
                                        {project.listPage.body.map((entry, index) => (
                                            <TableColumn
                                                key={`${item.cutId}-${index}`}
                                                className={
                                                    index === 1 ? 'md-text-center' : index === 2 ? 'md-text-right' : ''
                                                }
                                            >
                                                {item[entry] === 'Purchase Bales' || item[entry] === 'Consume Bales' ? 'Complete' : item[entry]}
                                            </TableColumn>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </DataTable>
                    </div>
                </div>
                <Notification />
                <Footer />
                <Tooltip fetchComplete={!showLoader} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    project: state.project,
    user: state.user,
    items: state.items,
});

const mapDispatchToProps = dispatch => ({
    storeItems: user => dispatch(storeItems(user)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(ListPage));
