import hljs from 'highlightjs';
import 'highlightjs/styles/github.css';
import React, { Component } from 'react';

class MessageContent extends Component {
    componentDidMount() {
        this.highlight();
    }

    componentDidUpdate() {
        this.highlight();
    }

    highlight = () => {
        try {
            hljs.highlightBlock(this.code);
        } catch (e) {
            // Failed to highlight
        }
    };

    render() {
        return (
            <div className="highlightjs-component">
                <pre className="prettyprint lang-json">
                    <code className="json prettyprint lang-json" ref={code => (this.code = code)}>
                        {JSON.stringify(this.props.message, null, 2)}
                    </code>
                </pre>
            </div>
        );
    }
}

export default MessageContent;
