import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { CardActions, FocusContainer, FontIcon, SelectField } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import Footer from '../../SharedComponents/Footer';
import Header from '../../SharedComponents/Header';
import Loader from '../../SharedComponents/Loader';
import Notification from '../../SharedComponents/Notification';
import Tooltip from '../../SharedComponents/Tooltip';
import { storeItem } from '../../store/item/actions';
import { addItem } from '../../store/items/actions';

const FERTILIZERS = ['Natural', 'Nitrogen', 'Phosphorus'];
const ADDITIVES_ORDER = ['Ensil 1', 'Ensil Pluss', 'Ensil Ekstra'];
const FILMS_ORDER = ['CropTight', 'Sigma U5', 'TrioBale Compressor'];
const ADDITIVES_EXISTING = ['Xtrasil Bio LP', 'Kofasil LP', 'GrasAAT Plus'];
const FILMS_EXISTING = ['Rondotex Evo XXL', 'Tama Edge', 'TrioBio'];

class MaterialForm extends Component {
    state = {
        showLoader: false,
        fertilizerError: false,
        fertilizer: '',
        additiveError: false,
        additive: '',
        filmError: false,
        film: ''
    };

    componentDidMount() {
        const {
            item,
            user,
            history
        } = this.props;

        if (isEmpty(user) || isEmpty(item)) {
            history.push('/login');
        }

        ReactGA.pageview('/material');
    }

    notifySuccess = message => toast.success(message);
    notifyError = message => toast.error(message);

    validate = () => {
        this.setState({
            fertilizerError: !this.state.fertilizer,
            additiveError: !this.state.additive,
            filmError: !this.state.film
        });

        return (
            !this.state.fertilizer ||
            !this.state.additive ||
            !this.state.film
        );
    };

    updateItem = (evt, save) => {
        evt.preventDefault();
        const { history, match: { params: { cutId, useExisting } } } = this.props;

        if (!save) {
            history.push(`/details/${cutId}`);
        } else {
            const formError = this.validate();

            if (!formError) {
                const { item } = this.props;

                if (save) {
                    last(item).materials = {
                        fertilizer: this.state.fertilizer,
                        silageAdditives: this.state.additive,
                        roundBaleFilm: this.state.film
                    };
                    history.push(`/details/${cutId}/${useExisting === '1' ? 'Existing Materials' : 'Order Materials'}`);
                } else {
                    history.push(`/details/${cutId}`);
                }
            } else {
                this.notifyError('Error with some of the input fields');
            }
        }
    };

    render() {
        const {
            showLoader,
            loaderHint,
            fertilizerError,
            additiveError,
            filmError
        } = this.state;
        const { match: { params: { useExisting } } } = this.props;

        return (
            <div className="form-page">
                <Header ctaEnabled>
                    <Col md={3} xl={4} className="heading hidden-md-down">
                        <span className="heading-text">
                            {useExisting === '1' ? 'Existing Materials' : 'Order Materials'}
                        </span>
                    </Col>
                </Header>
                <div className="content-stretch form-wrapper">
                    <FocusContainer
                        containFocus
                        component="form"
                        className="md-grid"
                        onSubmit={(evt) => this.updateItem(evt, true)}
                        aria-labelledby="update-item"
                    >
                        <p>{useExisting === '1' ? 'Please select the materials from stock' : 'Please select the materials to order for this cut.'}</p>
                        <SelectField
                            id="fertilizer"
                            required
                            label="Fertilizer"
                            className="md-cell select-fertilizer"
                            menuItems={FERTILIZERS}
                            position={SelectField.Positions.BELOW}
                            error={fertilizerError}
                            errorText="This field is required."
                            dropdownIcon={<FontIcon>expand_more</FontIcon>}
                            value={this.state.fertilizer}
                            onChange={(v) => this.setState({ fertilizer: v })}
                        />
                        <SelectField
                            id="additive"
                            required
                            label="Silage Additive"
                            className="md-cell"
                            menuItems={useExisting === '1' ? ADDITIVES_EXISTING : ADDITIVES_ORDER}
                            position={SelectField.Positions.BELOW}
                            error={additiveError}
                            errorText="This field is required."
                            dropdownIcon={<FontIcon>expand_more</FontIcon>}
                            value={this.state.additive}
                            onChange={(v) => this.setState({ additive: v })}
                        />
                        <SelectField
                            id="film"
                            required
                            label="Round Bale Film"
                            className="md-cell"
                            menuItems={useExisting === '1' ? FILMS_EXISTING : FILMS_ORDER}
                            position={SelectField.Positions.BELOW}
                            error={filmError}
                            errorText="This field is required."
                            dropdownIcon={<FontIcon>expand_more</FontIcon>}
                            value={this.state.film}
                            onChange={(v) => this.setState({ film: v })}
                        />
                    </FocusContainer>
                    <Notification />
                    <div>
                        <Loader showLoader={showLoader} text={loaderHint} />
                        <CardActions className="md-cell md-cell--12">
                            <button className={`button secondary ${showLoader ? 'hidden' : ''}`} onClick={(evt) => this.updateItem(evt, false)}>
                                Cancel
                            </button>
                            <button
                                className={`button ${useExisting === '1' ? 'use-existing-cta' : 'place-order-cta'} ${showLoader ? 'hidden' : ''}`}
                                onClick={(evt) => this.updateItem(evt, true)}>
                                {useExisting === '1' ? 'Use Materials' : 'Place Order'}
                            </button>
                        </CardActions>
                    </div>
                </div>
                <Tooltip />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    item: state.item,
    project: state.project,
});

const mapDispatchToProps = dispatch => ({
    addItem: cutId => dispatch(addItem(cutId)),
    storeItem: item => dispatch(storeItem(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withCookies(MaterialForm)));
