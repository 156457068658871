import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { CardActions } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import Footer from '../../SharedComponents/Footer';
import Header from '../../SharedComponents/Header';
import Loader from '../../SharedComponents/Loader';
import Notification from '../../SharedComponents/Notification';
import Tooltip from '../../SharedComponents/Tooltip';
import { storeItem } from '../../store/item/actions';
import { addItem } from '../../store/items/actions';
import cultivateSow from '../../assets/images/cultivate-sow.svg';
import cultivateBale from '../../assets/images/cultivate-bale.svg';
import cultivateFertilize from '../../assets/images/cultivate-fertilize.svg';
import cultivateHarvest from '../../assets/images/cultivate-harvest.svg';

const STEPS = ['Fertilize', 'Sow', 'Harvest', 'Bale', 'Finish'];
const IMAGES = [cultivateFertilize, cultivateSow, cultivateHarvest, cultivateBale];

class CultivateForm extends Component {
    state = {
        showLoader: false,
        step: 0,
        dates: [0, 0, 0, 0]
    };

    componentDidMount() {
        const {
            item,
            user,
            history
        } = this.props;

        if (isEmpty(user) || isEmpty(item)) {
            history.push('/login');
        }

        ReactGA.pageview('/cultivation');
    }

    notifySuccess = message => toast.success(message);
    notifyError = message => toast.error(message);

    updateItem = (save) => {
        const { history, match: { params: { cutId } } } = this.props;

        if (!save) {
            history.push(`/details/${cutId}`);
        } else {
            const { item } = this.props;

            if (save) {
                last(item).cultivation = {
                    fertilizeDate: this.state.dates[0],
                    sowDate: this.state.dates[1],
                    harvestDate: this.state.dates[2],
                    balingDate: this.state.dates[3]
                };
                history.push(`/details/${cutId}/Cultivate Crop`);
            } else {
                history.push(`/details/${cutId}`);
            }
        }
    };

    render() {
        const {
            showLoader,
            loaderHint,
        } = this.state;

        return (
            <div className="form-page">
                <Header ctaEnabled>
                    <Col md={3} xl={4} className="heading hidden-md-down">
                        <span className="heading-text">
                            Cultivate Crop
                        </span>
                    </Col>
                </Header>
                <div className="content-stretch form-wrapper">
                    { this.state.step < 4 && (
                        <p className="p-center">Please step through the processes required for cultivation.</p>
                    )}
                    { this.state.step === 4 && (
                        <p className="p-center">Click finish to record the cultivation process.</p>
                    )}
                    {[0, 1, 2, 3].map(i => (
                        <div key={i} className={`image-wrapper ${this.state.step === i ? 'fade-in' : ''}`}>
                            <img src={IMAGES[i]} alt={STEPS[i]} />
                        </div>
                    ))}
                    <Notification />
                    <div>
                        <Loader showLoader={showLoader} text={loaderHint} />
                        <CardActions className="md-cell md-cell--12">
                            <button className={`button secondary ${showLoader ? 'hidden' : ''}`} onClick={() => this.updateItem(false)}>
                                Cancel
                            </button>
                            <button
                                className={`button primary ${showLoader ? 'hidden' : ''} cta-cultivate`}
                                onClick={() => {
                                    if (this.state.step === 4) {
                                        this.updateItem(true);
                                    } else {
                                        const dates = this.state.dates;
                                        dates[this.state.step] = Date.now();
                                        this.setState({ dates, step: this.state.step + 1 });
                                    }
                                }}
                            >
                                <span>{STEPS[this.state.step]}</span>
                                {this.state.step < 4 && (
                                    <span>&gt;</span>
                                )}
                            </button>
                        </CardActions>
                    </div>
                </div>
                <Tooltip />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    item: state.item,
    project: state.project,
});

const mapDispatchToProps = dispatch => ({
    addItem: cutId => dispatch(addItem(cutId)),
    storeItem: item => dispatch(storeItem(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withCookies(CultivateForm)));
