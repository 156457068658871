import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import futureFarm from '../assets/images/future-farm.png';
import useCase1 from '../assets/images/intro/use-case-1.svg';
import useCase2 from '../assets/images/intro/use-case-2.svg';
import useCase3 from '../assets/images/intro/use-case-3.svg';
import useCase4 from '../assets/images/intro/use-case-4.svg';
import badge from '../assets/images/intro/badge.svg';
import milk from '../assets/images/intro/milk.svg';
import Footer from '../SharedComponents/Footer';
import Partners from '../SharedComponents/Partners';
import Disclaimer from '../SharedComponents/Disclaimer';

class IntroPage extends Component {
    componentDidMount() {
        ReactGA.pageview('/intro');
    }

    render() {
        return (
            <div className="intro-page">
                <Disclaimer />
                <div className="header">
                    <img src={futureFarm} className="logo" alt="Future Farm" />
                    <img src={badge} className="beta-badge" alt="Beta Version" />
                    <div className="hero-container">
                        <div className="left">
                            <div className="head-1">How Future Farm is Revolutionizing the grass-to-milk process</div>
                            <div className="sep">&nbsp;</div>
                            <div className="sub-1">
                                Using digitalization and the Distributed Ledger Technology to build an integrated value chain to create visibility from the grass production to a glass of milk.
                                <br /><br />
                                This demo showcases how sharing of data and collaboration around crop yield enables optimal production and facilitate a peer-to-peer marketplace for fodder between farmers.
                            </div>
                            <Link to="/login" className="button intro-button">
                                Try the demo
                            </Link>
                        </div>
                        <div className="right">
                            <img src={milk} alt="Milk" />
                        </div>
                    </div>
                </div>
                <Partners dark={true} />
                <div className="content">
                    <div id="problem">
                        <Row>
                            <Col xs={12}>
                                <h2 className="d-flex justify-content-responsive">
                                    The global problem
                                </h2>
                            </Col>
                            <Col xs={12} sm={6} className="d-flex flex-column justify-content-center">
                                <h1 className="text-responsive-align">
                                    Lack of visibility and data sharing in agriculture
                                </h1>
                                <p className="text-responsive-align">
                                    Multiple parties are involved in delivering dairy products to the breakfast table, including material suppliers, farmers, vets, laboratories, dairies, retailers etc.
                                    <br /><br />
                                    Data is not shared automatically between these parties and coordination is slowed down by the lack of a shared visibility into each actor’s input and action.
                                </p>
                            </Col>
                            <Col xs={12} sm={6} className="d-flex flex-column justify-content-center">
                                <img
                                    className="img-fluid use-cases-1"
                                    src={useCase1}
                                    alt="Use Cases 1"
                                />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                className="d-flex flex-column justify-content-center hidden-xs-down"
                            >
                                <img
                                    className="img-fluid use-cases-2"
                                    src={useCase2}
                                    alt="Use Cases 2"
                                />
                            </Col>
                            <Col xs={12} sm={6} className="d-flex flex-column justify-content-center">
                                <h1 className="text-responsive-align-alt">
                                    Leads to inefficiencies, high risk and unnecessary costs
                                </h1>
                                <p className="text-responsive-align-alt">
                                    It is estimated that deviation from best practice cost Norwegian agriculture hundreds of million NOK annually.
                                    <br /><br />
                                    The lack of a national overview of supply and demand creates a high risk situation with  major consequences when facing environmental impacts like drought.
                                </p>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                className="d-flex flex-column justify-content-center hidden-sm-up"
                            >
                                <img
                                    className="img-fluid use-cases-2"
                                    src={useCase2}
                                    alt="Use Cases 2"
                                />
                            </Col>
                        </Row>
                    </div>
                    <div id="solution">
                        <Row>
                            <Col xs={12}>
                                <h2 className="d-flex justify-content-responsive">
                                    The IOTA solution
                                </h2>
                            </Col>
                            <Col xs={12} sm={6} className="d-flex flex-column justify-content-center">
                                <h1 className="text-responsive-align">
                                    The Ledger provides Visibility & DigitalTwin of Round Bales
                                </h1>
                                <p className="text-responsive-align">
                                    Future Farm is an industry collaboration with the vision to use new technologies to enhance collaboration and create value for all participants. Distributed Ledger Technologies are used to create a new data infrastructure for a shared truth and higher transparency between all parties involved.
                                <br /><br />
                                    Furthermore, the technology can represent physical assets such as a Round Bale through the DigitalTwin concept. This will allow farmers to manage their stock of Round Bales, search for available Round Bales and ultimately create a national marketplace for Round Bales.
                                </p>
                            </Col>
                            <Col xs={12} sm={6} className="d-flex flex-column justify-content-center">
                                <img
                                    className="img-fluid use-cases-3"
                                    src={useCase3}
                                    alt="Use Cases 3"
                                />
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                className="d-flex flex-column justify-content-center hidden-xs-down"
                            >
                                <img
                                    className="img-fluid use-cases-4"
                                    src={useCase4}
                                    alt="Use Cases 4"
                                />
                            </Col>
                            <Col xs={12} sm={6} className="d-flex flex-column justify-content-center">
                                <h1 className="text-responsive-align-alt">
                                    IOTA provides data integrity and scalability for automation
                                </h1>
                                <p className="text-responsive-align-alt">
                                    The demo utilizes IOTA technology to take advantage of it’s feeless transactions and its scalability. The IOTA protocol is created for IOT (Internet of Things) and can therefore be integrated into smart devices and thus support the drive towards autonomous collaboration in the industry.
                                </p>
                            </Col>
                            <Col
                                xs={12}
                                sm={6}
                                className="d-flex flex-column justify-content-center hidden-sm-up"
                            >
                                <img
                                    className="img-fluid use-cases-4"
                                    src={useCase4}
                                    alt="Use Cases 4"
                                />
                            </Col>
                        </Row>
                    </div>
                    <div id="tryit" className="demo alternate">
                        <Row>
                            <Col sm={{ size: 8, offset: 2 }} xs={12}>
                                <h2 className="d-flex justify-content-responsive">
                                    Explore our demo
                                </h2>
                                <p className="d-flex justify-content-responsive">
                                    Try the demo to cultivate a field and harvest a cut to produce a set of round bales. Learn how different parties can share data in an immutable way and ensure that the produced round bales are digitally born with all relevant data to enable a marketplace where farmers continuously can track all input that ultimately goes into creating a glass of milk.
                                </p>
                            </Col>
                            <Col xs={12} className="d-flex justify-content-center">
                                <Link to="/login" className="button demo-button">
                                    Try the demo
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Partners />
                <Footer showLearn={true} />
            </div>
        );
    }
}

export default IntroPage;
