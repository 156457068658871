import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { CardActions, FocusContainer, TextField } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import Footer from '../../SharedComponents/Footer';
import Header from '../../SharedComponents/Header';
import Loader from '../../SharedComponents/Loader';
import Notification from '../../SharedComponents/Notification';
import Tooltip from '../../SharedComponents/Tooltip';
import { storeItem } from '../../store/item/actions';
import { addItem } from '../../store/items/actions';
import { generateHash } from '../../utils/mam';

class BalesPurchaseForm extends Component {
    state = {
        showLoader: false,
        quantityError: false,
        quantity: '',
        maxQuantity: 0
    };

    componentDidMount() {
        const {
            item,
            user,
            history
        } = this.props;

        if (isEmpty(user) || isEmpty(item)) {
            history.push('/login');
        } else {
            ReactGA.pageview('/balespurchase');

            const lastItem = last(item);
            this.setState({ maxQuantity: lastItem.bales.filter(b => !b.purchaser && !b.consumed).length });
        }
    }

    notifySuccess = message => toast.success(message);
    notifyError = message => toast.error(message);

    validate = () => {
        const quantity = parseInt(this.state.quantity, 10);
        const isValid = Number.isInteger(quantity) && quantity > 0 && quantity <= this.state.maxQuantity;

        this.setState({
            quantityError: !isValid
        });

        return !isValid;
    };

    updateItem = (evt, save) => {
        evt.preventDefault();
        const { history, match: { params: { cutId } } } = this.props;

        if (!save) {
            history.push(`/details/${cutId}`);
        } else {
            const formError = this.validate();

            if (!formError) {
                const { item } = this.props;

                if (save) {
                    const numBales = parseInt(this.state.quantity, 10);

                    const purchaser = generateHash(81);

                    const lastItem = last(item);

                    const usable = lastItem.bales.filter(b => !b.purchaser && !b.consumed);

                    for (let i = 0; i < numBales; i++) {
                        usable[i].purchaser = purchaser;
                    }

                    history.push(`/details/${cutId}/Purchase Bales`);
                } else {
                    history.push(`/details/${cutId}`);
                }
            } else {
                this.notifyError('Error with some of the input fields');
            }
        }
    };

    render() {
        const {
            showLoader,
            loaderHint,
            quantityError
        } = this.state;

        return (
            <div className="form-page">
                <Header ctaEnabled>
                    <Col md={3} xl={4} className="heading hidden-md-down">
                        <span className="heading-text">
                            Purchase Bales
                        </span>
                    </Col>
                </Header>
                <div className="content-stretch form-wrapper">
                    <FocusContainer
                        containFocus
                        component="form"
                        className="md-grid"
                        onSubmit={(evt) => this.updateItem(evt, true)}
                        aria-labelledby="update-item"
                    >
                        {this.state.maxQuantity === 0 && (
                            <p>There are no bales remaining in this cut to purchase.</p>
                        )}
                        {this.state.maxQuantity > 0 && (
                            <React.Fragment>
                                <p>Please enter the number of bales you want to purchase, the maximum is {this.state.maxQuantity}.</p>
                                <TextField
                                    value={this.state.quantity}
                                    onChange={(v) => this.setState({ quantity: v })}
                                    id="quantity"
                                    label="Bale Quantity"
                                    required
                                    type="text"
                                    error={quantityError}
                                    errorText={`This field must be a whole number > 0 and <= ${this.state.maxQuantity}`}
                                />
                            </React.Fragment>
                        )}
                    </FocusContainer>
                    <Notification />
                    <div>
                        <Loader showLoader={showLoader} text={loaderHint} />
                        <CardActions className="md-cell md-cell--12">
                            <button className={`button secondary ${showLoader ? 'hidden' : ''}`} onClick={(evt) => this.updateItem(evt, false)}>
                                Cancel
                            </button>
                            {this.state.maxQuantity > 0 && (
                                <button className={`button purchase-cta ${showLoader ? 'hidden' : ''}`} onClick={(evt) => this.updateItem(evt, true)}>
                                    Purchase
                                </button>
                            )}
                        </CardActions>
                    </div>
                </div>
                {this.state.maxQuantity > 0 && (
                    <Tooltip />
                )}
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    item: state.item,
    project: state.project,
});

const mapDispatchToProps = dispatch => ({
    addItem: cutId => dispatch(addItem(cutId)),
    storeItem: item => dispatch(storeItem(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withCookies(BalesPurchaseForm)));
