import React, { Component, lazy, Suspense } from 'react';
import { DataTable, TableBody } from 'react-md';
import { connect } from 'react-redux';
import Loader from '../../SharedComponents/Loader';
import FilesUpload from './FilesUpload';

const Document = lazy(() => import('./Document'));

class Documents extends Component {
    render() {
        const {
            item,
            fileUploadEnabled,
            onUploadComplete,
            user,
            project: { documentStorage, trackingUnit }
        } = this.props;

        return (
            <div className="documents-wrapper">
                <Suspense maxDuration={1000} fallback={<Loader showLoader />}>
                    <DataTable plain>
                        <TableBody>
                            {item.documents.map((document, index) => (
                                <Document key={`${document.name}-${index}`} document={document} />
                            ))}
                        </TableBody>
                    </DataTable>
                    {documentStorage && fileUploadEnabled && user.canUploadDocuments ? (
                        <FilesUpload
                            uploadComplete={onUploadComplete}
                            pathTofile={`${trackingUnit.replace(/\s/g, '')}/${item.cutId}`}
                            existingDocuments={item.documents}
                        />
                    ) : null}
                </Suspense>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    project: state.project,
    user: state.user
});

export default connect(mapStateToProps)(Documents);
