import React from 'react';
import 'react-app-polyfill/ie9';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import WebFontLoader from 'webfontloader';
import './assets/scss/index.scss';
import Router from './Router';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configure';
import { initializeFirebaseApp } from './utils/firebase';

WebFontLoader.load({
    google: {
        families: ['Open Sans:300,400,600,700', 'Material Icons'],
    },
});

initializeFirebaseApp();
const store = configureStore();

ReactGA.initialize('UA-134592666-11'); // (trackingID, { debug: true })
ReactGA.set({ anonymizeIp: true });

const renderApp = () => (
    <Provider store={store}>
        <Router />
    </Provider>
);

ReactDOM.render(renderApp(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
