import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import futureFarm from '../assets/images/future-farm.png';
import Footer from '../SharedComponents/Footer';
import Partners from '../SharedComponents/Partners';

class IntroPage extends Component {
    componentDidMount() {
        ReactGA.pageview('/tour');
    }

    render() {
        return (
            <div className="intro-page tour-page">
                <div className="header">
                    <Link to="/"><img src={futureFarm} className="logo" alt="Future Farm" /></Link>
                    <div className="hero-container">
                        <div className="left">
                            <div className="head-1">Congratulations</div>
                            <div className="head-2">The Round Bales are issued as a digital asset</div>
                            <div className="sep">&nbsp;</div>
                            <div className="sub-1">
                                Each Round Bale is represented on the Tangle where it can be managed by its owner
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="extra-padding" id="tour">
                        <Row>
                            <Col xs={12}>
                                <h2 className="d-flex justify-content-responsive">
                                    Thank you for completing the tour
                                </h2>
                            </Col>
                            <Col sm={{ size: 8, offset: 2 }} xs={12} >
                                <p>The round bales can be:</p>
                                <ul className="tour--list">
                                    <li>Put on the market for other farmers to purchase.</li>
                                    <li>Track its whereabouts including whether it is sold, consumed or stored for later use.</li>
                                    <li>Access the full provenance including quality, cultivation process and what material input went in.</li>
                                    <li>Track number of round bales available for each fodder type (milk cows, calf, etc.)</li>
                                    <li>Treat it as fodder input into a digital diary process for analysis and prediction processes<br />ultimately allowing end-consumers full visibility of all events and material going into a glass of milk</li>
                                </ul>
                            </Col>
                            <Col sm={{ size: 8, offset: 2 }} xs={12} className="d-flex flex-column justify-content-center">
                                <p>
                                    The demo illustrated how physical assets can be digitized and managed as entities with unique characteristics such as ownership and trusted asset information made available by the source. Access to information and models for collaboration can be designed using the baked-in encryption  and digital identities. It provides the groundwork for many different purposes, whether it is to build a marketplace for round bales, to do analytics on the correlations between type of grass and milk quality or to share full history with the end-consumer of milk.
                                </p>

                                <p>
                                    The world needs more food to meet a growing population. High-tech agriculture is here, and it is a prerequisite for everyone within agriculture to be able to produce more food while reducing the climate footprint.
                                </p>

                                <p>
                                    Through the research project “Future Farm”, sponsored by the Norwegian Research Council, a consortium of key players within Norwegian agriculture have joined forces to develop Norwegian food and feed production.  With an ambition to become a world leader in precision solutions, which utilize resources in the best possible way, the partners in the research project will strengthen and develop the competitiveness of Norwegian agriculture, and give the farmer ownership of future agriculture.
                                </p>

                                <p>
                                    Future Farm has partnered with the IOTA Foundation to create the digital building blocks. This project will allow farmers to manage their stock, purchase and sell round bales and ultimately create a national marketplace.
                                </p>

                            </Col>
                        </Row>
                    </div>
                    <div className="alternate extra-padding" id="more">
                        <Row>
                            <Col sm={{ size: 8, offset: 2 }} xs={12}>
                                <h2 className="d-flex justify-content-responsive">
                                    Want more?
                                </h2>
                                <p>
                                    Future Farm is a collaboration of TINE, Yara, Felleskjøpet, Eurofins, inFuture, and Norwegian University of Life Sciences
                                </p>
                                <p>
                                    <a href="https://www.tine.no/" target="_blank" rel="noopener noreferrer">www.tine.no</a><br />
                                    <a href="https://www.eurofins.no/" target="_blank" rel="noopener noreferrer">www.eurofins.no</a><br />
                                    <a href="https://www.felleskjopet.no/" target="_blank" rel="noopener noreferrer">www.felleskjopet.no</a><br />
                                    <a href="https://www.yara.no/" target="_blank" rel="noopener noreferrer">www.yara.no</a><br />
                                    <a href="https://infuture.no/" target="_blank" rel="noopener noreferrer">infuture.no</a><br />
                                    <a href="https://www.nmbu.no/" target="_blank" rel="noopener noreferrer">www.nmbu.no</a><br />
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="extra-padding" id="contact">
                        <Row>
                            <Col sm={{ size: 8, offset: 2 }} xs={12}>
                                <h2 className="d-flex justify-content-responsive">
                                    Contact
                                </h2>
                                <p className="justify-content-responsive">
                                    Simon Arenberg, Project manager<br />
                                    <a href="mailto:simon.arenberg@infuture.no">simon.arenberg@infuture.no</a>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Partners />
                <Footer showHome={true} />
            </div>
        );
    }
}

export default IntroPage;
