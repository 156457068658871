/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';

export default ({ showLearn, showHome }) => (
    <footer>
        <div>Email: <a href="mailto:simon.arenberg@infuture.no">simon.arenberg@infuture.no</a></div>
        <div>
            {showLearn && (
                <Link to="/tour">
                    Learn More
                </Link>
            )}
            {showHome && (
                <Link to="/">
                    Home
                </Link>
            )}
        </div>
        <div>&copy; 2019 Future Farm | Powered By <a href="https://www.iota.org" target="_blank" rel="noopener noreferrer">IOTA</a></div>
    </footer>
);
