import React from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';

class Disclaimer extends React.Component {
    state = { ack: true }

    componentDidMount() {
        const ack = this.props.cookies.get('ack');
        if (!ack) {
            this.setState({ ack: false });
        }
    }

    dismiss = () => {
        this.props.cookies.set('ack', true, { path: '/', expires: new Date(2050, 1, 1) });
        this.setState({ ack: true });
    }

    render() {
        if (this.state.ack) return null;

        return (
            <div className="disclaimer">
                <span className="disclaimer-text">
                    This website uses cookies to ensure you get the best experience on our
                    website.&nbsp;
          <ReactGA.OutboundLink
                        className="disclaimer-link"
                        eventLabel="https://www.iota.org/research/privacy-policy"
                        to="https://www.iota.org/research/privacy-policy"
                    >
                        Learn more
          </ReactGA.OutboundLink>
                </span>
                <button className="button" onClick={this.dismiss}>Dismiss</button>
            </div>
        );
    }
}

export default withCookies(Disclaimer);
