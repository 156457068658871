import * as moment from 'moment';
import React from 'react';

const roles = {
    'Prepared': 'Farmer 1',
    'Order Materials': 'Farmer 1',
    'Materials Received': 'Farmer 1',
    'Existing Materials': 'Farmer 1',
    'Cultivate Crop': 'Farmer 1',
    'Send for Analysis': 'Farmer 1',
    'Analysis': 'Laboratory',
    'Create Bale Batch': 'Farmer 1',
    'Consume Bales': 'Farmer 1',
    'Purchase Bales': 'Farmer 2'
};

const StatusList = ({ statuses }) => (
    <div className="status-wrapper">
        {statuses.map(({ status, timestamp }) => (
            <div key={timestamp} className="status">
                <span className="value">{status}</span>
                <span className="value">({roles[status]})</span>
                <div>
                    <span className="day">{moment(timestamp).format('D')}</span>
                    <span className="month">{moment(timestamp).format('MMMM')}</span>
                    <span className="time">{moment(timestamp).format('LT')}</span>
                </div>
            </div>
        ))}
    </div>
);

export default StatusList;
