import axios from 'axios';
import { sha256 } from 'js-sha256';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Col } from 'reactstrap';
import farmer1 from '../assets/images/role-avatars/farmer1.svg';
import farmer2 from '../assets/images/role-avatars/farmer2.svg';
import laboratory from '../assets/images/role-avatars/laboratory.svg';
import config from '../config.json';
import Footer from '../SharedComponents/Footer';
import Header from '../SharedComponents/Header';
import Loader from '../SharedComponents/Loader';
import Tooltip from '../SharedComponents/Tooltip';
import { storeEventMappings, storeProjectSettings } from '../store/project/actions';
import { storeCredentials, storeEvents } from '../store/user/actions';
import updateStep from '../utils/cookie';

const images = { farmer1, farmer2, laboratory };

class LoginPage extends Component {
    state = {
        showLoader: false,
        selectedRole: null
    };

    async componentDidMount() {
        const { cookies, loadEventMappings, loadProjectSettings } = this.props;
        await loadProjectSettings();
        ReactGA.pageview('/login');
        loadEventMappings();
        const tourStep = cookies.get('tourStep');
        if (!tourStep) {
            cookies.set('tourStep', 0, { path: '/' });
        }
        //cookies.set('tourStep', 24, { path: '/' });  
        //cookies.set('cutId', "test")
    }

    loginAs = (event, role) => {
        const { cookies, history, storeCredentials, storeEvents } = this.props;
        event.preventDefault();
        this.setState({ showLoader: true, selectedRole: role });
        const password = sha256(role.toLowerCase());

        role === 'farmer-1' && updateStep(cookies, 1);
        role === 'farmer-1' && updateStep(cookies, 24);
        role === 'laboratory' && updateStep(cookies, 17);
        role === 'farmer-2' && updateStep(cookies, 27);

        axios
            .post(`${config.rootURL}/login`, { username: role, password })
            .then(response => {
                storeCredentials(response.data);
                storeEvents(response.data.role);

                ReactGA.event({
                    category: 'Login',
                    action: `Logged in as ${role}`
                });

                history.push('/list');
            })
            .catch(error => {
                this.setState({ showLoader: false, selectedRole: null });
                toast.error(
                    error.response && error.response.data && error.response.data.error
                        ? error.response.data.error
                        : 'Authentication error'
                );
            });
    };

    render() {
        const { showLoader, selectedRole } = this.state;
        const { project } = this.props;

        if (isEmpty(project)) return <div />;

        return (
            <div className="login-page">
                <Header ctaEnabled>
                    <Col md={3} xl={4} className="heading hidden-md-down">
                        <span className="heading-text">
                            Log in to your user role
            </span>
                    </Col>
                </Header>
                <div className="content-stretch">
                    <div className="roles-wrapper">
                        {
                            project.roles.map(role => (
                                <div className="role-wrapper" key={role.id}>
                                    <div className="role-wrapper-inner">
                                        <div className="role-icon">
                                            <img alt={role.name} src={images[role.id.replace(/-/g, '')]} />
                                        </div>
                                        <div className="role-info">
                                            <div className="role-name">
                                                {upperFirst(role.id.replace(/-/g, ' '))}
                                            </div>
                                            <div className="role-description">
                                                {role.description}
                                            </div>
                                            {!showLoader && (
                                                <div className="role-cta">
                                                    <button
                                                        className={`button ${role.id}-cta`}
                                                        onClick={event => this.loginAs(event, role.id)}
                                                    >
                                                        Log in
                    </button>
                                                </div>
                                            )}
                                            {showLoader && (
                                                <div className="role-loader">
                                                    {showLoader && selectedRole === role.id && (
                                                        <Loader showLoader={true} />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Footer />
                <Tooltip />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    project: state.project,
});

const mapDispatchToProps = dispatch => ({
    loadProjectSettings: () => dispatch(storeProjectSettings()),
    loadEventMappings: () => dispatch(storeEventMappings()),
    storeCredentials: credentials => dispatch(storeCredentials(credentials)),
    storeEvents: role => dispatch(storeEvents(role)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(LoginPage));
