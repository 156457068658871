/* eslint-disable no-console */
import { getEvents } from '../../../utils/firebase';
import { AUTH, LOAD_EVENTS, LOGOUT } from '../../actionTypes';

export const storeCredentials = data => ({
    type: AUTH,
    payload: data,
});

export const logout = () => ({
    type: LOGOUT,
});

export const storeEvents = role => {
    const promise = getEvents(role);
    return {
        type: LOAD_EVENTS,
        promise,
    };
};
