/* eslint-disable no-console */
import { getItem, getItems } from '../../../utils/firebase';
import { ADD_ITEM, STORE_ITEMS } from '../../actionTypes';

export const addItem = cutId => {
    const promise = getItem(cutId);
    return {
        type: ADD_ITEM,
        promise,
    };
};

export const storeItems = (user, cutId = null) => {
    const promise = new Promise(async (resolve, reject) => {
        try {
            const results = {};
            const promises = [];

            if (user.role === 'farmer-1') {
                // For farmer-1 we show all the items
                promises.push(getItems());
            } else {
                // For the other roles laboratory and farmer-2 we show only those in the specific states
                user.previousEvent.forEach(status => {
                    promises.push(getItems(status));
                });
            }

            if (cutId) {
                // Add a container under review, which status was already changed
                promises.push(getItem(cutId));
            }

            // const newResults = {}
            await Promise.all(promises)
                .then(items => {
                    items.forEach(item => {
                        // Sort by most recently changed first
                        Object.values(item).sort((a, b) => b.timestamp - a.timestamp).forEach(result => {
                            if (!results[result.cutId]) {
                                results[result.cutId] = result;
                            }
                        });
                    });
                })
                .catch(() => {
                    return reject({ error: 'Loading items failed' });
                });

            return resolve({ data: results, error: null });
        } catch (error) {
            return reject({ error: 'Loading items failed' });
        }
    });

    return {
        type: STORE_ITEMS,
        promise,
    };
};
