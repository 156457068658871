/* eslint-disable react/display-name */
import React from 'react';
import partner1 from '../../assets/images/partner1.png';
import partner2 from '../../assets/images/partner2.png';
import partner3 from '../../assets/images/partner3.png';
import partner4 from '../../assets/images/partner4.png';
import partner5 from '../../assets/images/partner5.png';
import partner6 from '../../assets/images/partner6.png';

export default ({ dark }) => (
    <div className={`partners ${dark ? 'dark' : ''}`}>
        <img src={partner1} alt="Partner" />
        <img src={partner2} alt="Partner" />
        <img src={partner4} alt="Partner" />
        <img src={partner3} alt="Partner" />
        <img src={partner6} alt="Partner" />
        <img src={partner5} alt="Partner" />
    </div>
);
