import React from 'react';

export default ({ showLoader, text = null }) => showLoader && (
    <React.Fragment>
        <div className="bouncing-loader">
            <div className="bouncing-loader__item-1" />
            <div className="bouncing-loader__item-2" />
            <div className="bouncing-loader__item-3" />
        </div>
        {
            text && (
                <div className="bouncing-loader-text-wrapper">
                    <span className="bouncing-loader-text">{text}</span>
                </div>
            )
        }
    </React.Fragment>
);
