import _get from 'lodash/get';
import _last from 'lodash/last';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Joyride from 'react-joyride';
import close from '../../assets/images/tooltip-close.svg';
import updateStep from '../../utils/cookie';
import ErrorBoundary from '../ErrorBoundary';
import tooltips from './tooltips';

class Tooltip extends Component {
    static defaultProps = {
        customTooltip: null,
        fetchComplete: null,
        activeTabIndex: null
    }

    state = {
        showMobileTooltip: false,
        closeFn: null,
        tooltips: tooltips.map(tooltip => ({ ...tooltip, disableBeacon: true }))
    };

    callback = ({ action, index }) => {
        const { cookies } = this.props;
        if (action === 'skip') {
            updateStep(cookies, index + 1);
        } else if (action === 'update') {
            this.setState({ showMobileTooltip: true });
        } else if (action === 'close') {
            const newTooltips = this.state.tooltips;
            delete newTooltips[index].disableBeacon;
            this.setState({ tooltips: newTooltips, showMobileTooltip: false });
        }
    }

    extractContent = element => {
        const last = _last(_get(element, 'props.children'));
        return _get(last, 'props.children');
    }

    helpers = props => this.setState({ closeFn: props.close });

    stopTour = () => {
        if (this.state.closeFn) {
            this.state.closeFn();
        }
        this.setState({ showMobileTooltip: false });
    }

    render() {
        const { cookies, customTooltip, fetchComplete } = this.props;
        const stepIndex = Number(cookies.get('tourStep') || 0);
        const { showMobileTooltip } = this.state;

        if (fetchComplete === false) {
            return null;
        }

        const spotlightStyles = {
            border: '2px solid #FDC47B',
            backgroundColor: 'transparent',
            borderRadius: tooltips[stepIndex].isRounded ? '100vw' : '0',
            boxShadow: 'unset',
            color: '#000000'
        };

        return (
            <React.Fragment>
                {
                    showMobileTooltip ? (
                        <div className="mobile-tooltip" role="button" onClick={this.stopTour}>
                            <div className="tooltip-content">
                                <span className="tooltip-step">
                                    Step {stepIndex + 1}
                                </span>
                                <span className="tooltip-action">
                                    {this.extractContent(tooltips[stepIndex].content)}
                                </span>
                            </div>
                            <img alt="close" src={close} className="tooltip-close" />
                        </div>
                    ) : null
                }
                <ErrorBoundary>
                    <Joyride
                        steps={customTooltip || this.state.tooltips}
                        stepIndex={!customTooltip ? stepIndex : null}
                        callback={this.callback}
                        getHelpers={this.helpers}
                        hideBackButton
                        spotlightClicks
                        styles={{
                            options: {
                                overlayColor: 'transparent',
                                primaryColor: '#FDC47B',
                                textColor: '#000000',
                                zIndex: 1000,
                            },
                            overlay: {
                                mixBlendMode: 'normal'
                            },
                            overlayLegacy: {
                                mixBlendMode: 'normal'
                            },
                            spotlight: {
                                ...spotlightStyles
                            },
                            spotlightLegacy: {
                                ...spotlightStyles
                            }
                        }}
                        floaterProps={{
                            wrapperOptions: {
                                offset: -22,
                                placement: 'top',
                                position: true,
                            }
                        }}
                    />
                </ErrorBoundary>
            </React.Fragment>
        );
    }
}

export default withCookies(Tooltip);
