import isEmpty from 'lodash/isEmpty';
import { handle } from 'redux-pack';
import { ADD_ITEM, STORE_ITEMS } from '../../actionTypes';

const initialState = {};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_ITEMS:
            if (isEmpty(payload)) return state;
            return handle(state, action, {
                success: () => {
                    return {
                        ...payload.data,
                    };
                },
                failure: () => {
                    return {
                        error: payload.error,
                    };
                },
            });
        case ADD_ITEM:
            if (isEmpty(payload)) return state;
            return handle(state, action, {
                success: prevState => {
                    const cutId = payload.cutId;
                    return {
                        ...prevState,
                        [cutId]: payload,
                    };
                },
                failure: prevState => {
                    return {
                        ...prevState,
                        error: 'Loading items failed',
                    };
                },
            });
        default:
            return state;
    }
};
