import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import futureFarm from '../../assets/images/future-farm.png';
import { reset } from '../../store/project/actions';
import { logout } from '../../store/user/actions';
import updateStep from '../../utils/cookie';

const CallToAction = ({ logout, reset, user }) => (
    <Col xs={6} lg={5} xl={4} className="cta">
        <div className="header-cta-wrapper">
            <Row>
                <Col xs={5} className="hidden-sm-down">
                    <h4>
                        User Role
          </h4>
                    {
                        !isEmpty(user) ? (
                            <span>
                                {upperFirst(user.role.replace(/-/g, ' '))}
                            </span>
                        ) : (
                                <span>
                                    Logged out
              </span>
                            )
                    }
                </Col>
                <Col xs={7} className="button-wrapper">
                    {
                        !isEmpty(user) ? (
                            <button className="button primary logout-cta" onClick={logout}>
                                Log out
              </button>
                        ) : (
                                <button className="button secondary reset-cta" onClick={reset}>
                                    Reset demo
              </button>
                            )
                    }
                </Col>
            </Row>
        </div>
    </Col>
);

class Header extends Component {
    logout = () => {
        const { cookies, history, logout, user } = this.props;

        logout();
        updateStep(cookies, 16);
        updateStep(cookies, 23);
        updateStep(cookies, 26);

        ReactGA.event({
            category: 'Logout',
            action: `Logout from ${user.role}`
        });

        if (Number(cookies.get('tourStep')) === 32) {
            ReactGA.event({
                category: 'Tour',
                action: 'Tour completed',
                label: `Cut ID ${cookies.get('cutId')}`
            });

            cookies.remove('tourStep');
            cookies.remove('cutId');

            history.push('/tour');
        } else {
            history.push('/login');
        }
    };

    reset = () => {
        const { cookies, reset } = this.props;

        let cutIdString = '';
        if (cookies.get('cutId')) {
            cutIdString = `Cut ID ${cookies.get('cutId')}`;
        }
        ReactGA.event({
            category: 'Reset',
            action: 'Reset tour',
            label: cutIdString,
            value: Number(cookies.get('tourStep'))
        });

        cookies.remove('tourStep');
        cookies.remove('cutId');
        reset();
        window.location.reload();
    };

    render() {
        const { children, className, ctaEnabled = false, user } = this.props;
        return (
            <React.Fragment>
                <header className={className}>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between align-items-start">
                            <Col xs={4}>
                                <Link to="/">
                                    <img className="logo" src={futureFarm} alt="Future Farm" />
                                </Link>
                            </Col>
                            {children}
                            {ctaEnabled ? <CallToAction logout={this.logout} reset={this.reset} user={user} /> : null}
                        </Col>
                    </Row>
                </header>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withCookies(Header)));
